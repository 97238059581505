<template>
  <b-card title="Speakers">

    <b-tabs>

      <b-tab>
        <template #title>
          <feather-icon icon="PlusIcon" />
          <a href="">Add Speaker</a>
        </template>

        <AddSpeaker @add-category="getData" />
      </b-tab>
      <b-tab @click="$router.push('/all-speakers')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <!-- <a  href="/all-categories">All Categories</a> -->
          <span>All Speakers</span>
        </template>
        <router-view />
        <!-- <SpeakersList ref="allData"></SpeakersList> -->
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import AddSpeaker from './AddSpeaker.vue'

export default {
  components: {

    BCard,
    BTabs,
    BTab,

    AddSpeaker,
  },
  setup() {
    const allData = ref()

    const getData = () => {

      // allData.value.getSpeakers()

    }
    return {
      getData,
      allData,

    }
  },
}
</script>
